import React, { memo, useEffect, useState, useRef, useCallback } from "react";
import {
  IconButton,
  Stack,
  Typography,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import { Send, ExpandMore } from "@mui/icons-material";
import {
  addGroupChatMessage,
  addRecipientChatMessage,
  fetchAllPinnedMessage,
  getEventAllMessages,
  getRecipientAllMessages,
  pinMessage,
  unpinMessage
} from "services/dashboardServices";
import MessageComponent from "./messageComponent";
import io from "socket.io-client";
import AuthActions from "redux/auth/actions";
import "./chatView.css";
import LockScreenOverlay from "./LockScreenOverlay";
import { Audio } from "react-loader-spinner";

const { success, error } = AuthActions;
// const SOCKET_SERVER_URL = "http://localhost:3001";
const SOCKET_SERVER_URL = "https://test.bandlr.com";
let socket;

const generateChatRoomId = (userId1, userId2) =>
  [userId1, userId2].sort().join("_");

const ChatViewComponent = ({ user, groupId, isPersonal }) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [replyTo, setReplyTo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasSentFirstMessage, setHasSentFirstMessage] = useState(false);
  const messagesEndRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);
  const [pinnedMessages, setPinnedMessages] = useState([]);

  // const suggestions = [
  //   `Hey, I'm ${user.name}, looking forward to knowing everyone here!`,
  //   "abcdefgh",
  //   "gotta go, bye...!",
  // ];

  const chatRoomId = isPersonal
    ? generateChatRoomId(user.userId, groupId)
    : groupId;

  const getChatMessage = async () => {
    if (!hasMore || loading) return;
    try {
      setLoading(true);
      const page = currentPage + 1;
      let data;
      if (!isPersonal) {
        data = await getEventAllMessages(user.token, groupId, page, 10);
      } else {
        data = await getRecipientAllMessages(user.token, groupId);
      }
      if (data && data.data.length > 0 && Array.isArray(data.data)) {
        setMessages(prevMessages => [...prevMessages, ...data.data]);
        setCurrentPage(page);
        setHasMore(data.data.length === 10);
        console.log("Fetching messages for page:", page);
        const userHasSentMessages = data.data.some(
          msg => msg.userId === user.userId
        );
        setHasSentFirstMessage(userHasSentMessages);
      } else {
        setHasMore(false);
      }
      if (!isPersonal) {
        socket.emit("join chat", groupId);
      } else {
        socket.emit("join personalChat", user.userId, groupId);
      }
    } catch (err) {
      console.error("Error fetching messages:", err);
    } finally {
      setLoading(false);
    }
  };

  const fetchPinnedMessages = async () => {
    try {
      const response = await fetchAllPinnedMessage(user.token, groupId);
      console.log(Array.isArray(response.data));
      if (response && Array.isArray(response.data)) {
        setPinnedMessages([...response.data]);
      } else {
        setPinnedMessages([]);
      }
    } catch (err) {
      console.error("Error fetching pinned messages:", err);
    }
  };

  const pinThisMessage = async messageId => {
    console.log("pinning message");
    try {
      await pinMessage(user.token, groupId, messageId);
      const response = await fetchAllPinnedMessage(user.token, groupId);
      setPinnedMessages(prev => [...response.data]);
    } catch (error) {
      console.error("Error pinning message:", error);
    }
  };

  const unpinThisMessage = async messageId => {
    try {
      await unpinMessage(messageId);
      setPinnedMessages(prev => prev.filter(msg => msg._id !== messageId));
    } catch (error) {
      console.error("Error unpinning message:", error);
    }
  };

  useEffect(() => {
    getChatMessage();
    fetchPinnedMessages();
  }, [groupId]);

  useEffect(() => {
    setMessages([]);
    setHasSentFirstMessage(false);
  }, [groupId]);

  useEffect(() => {
    socket = io(SOCKET_SERVER_URL);
    socket.emit("setup", { user, groupId: chatRoomId });
    socket.on("connected", () => console.log("Socket connected"));
    socket.on("message received", handleNewMessage);
    socket.on("personalMessage received", handleNewPersonalMessage);
    return () => {
      socket.off("connected");
      socket.off("message received", handleNewMessage);
      socket.off("personalMessage received", handleNewPersonalMessage);
      socket.disconnect();
    };
  }, [groupId]);

  const handleNewMessage = useCallback(
    newMessage => {
      if (newMessage.eventId === groupId) {
        // setMessages((prevMessages) => [...prevMessages, newMessage]);
        setMessages(prevMessages => [newMessage, ...prevMessages]);
        scrollToBottom();
      }
    },
    [groupId]
  );

  const handleSuggestionClick = suggestion => {
    setMessage(suggestion);
  };

  const handleNewPersonalMessage = useCallback(
    data => {
      if (data.room === chatRoomId) {
        setMessages(prevMessages => [...prevMessages, data.newMessage]);
        scrollToBottom();
      }
    },
    [chatRoomId]
  );

  const submitHandler = async e => {
    e.preventDefault();
    if (!message) return;

    console.log(replyTo);
    const data = {
      userId: user.userId,
      groupId: groupId,
      message: message,
      profileImage: user.profileImage,
      userInfo: {
        _id: user.userId,
        name: user.name
      },
      replyTo: replyTo
        ? {
            message: replyTo.message,
            userInfo: replyTo.userInfo,
            msgId: replyTo.msgId,
            userId: replyTo.userId
          }
        : null
    };
    console.log(data);
    setMessage("");
    try {
      let response;
      if (!isPersonal) {
        // console.log(data)
        response = await addGroupChatMessage(user.token, data);
        socket.emit("new message", response.data);
      } else {
        response = await addRecipientChatMessage(user.token, data);
        socket.emit("new personalMessage", response.data);
      }
      // console.log(response)
      setMessage("");
      setReplyTo(null);
      setHasSentFirstMessage(true);
      setShouldScrollToBottom(true);
      // scrollToBottom();
    } catch (err) {
      console.error("Error sending message:", err);
    }
  };

  const handleMessage = e => {
    setMessage(e.target.value);
  };

  const handleReply = message => {
    setReplyTo(message);
  };

  const scrollToBottom = () => {
    requestAnimationFrame(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
      }
    });
  };

  useEffect(() => {
    if (shouldScrollToBottom) {
      scrollToBottom();
      setShouldScrollToBottom(false);
    }
  }, [messages]);

  const handleLoadMore = () => {
    setShouldScrollToBottom(false);
    getChatMessage();
  };

  console.log(hasSentFirstMessage);

  return (
    <div className="chat-view-container">
      <div className="pinned-messages-container">
        {pinnedMessages.length > 0 ? (
          <Accordion
            sx={{
              width: "100%"
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="pinned-messages-content"
              id="pinned-messages-header"
              sx={{
                minHeight: 0,
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                  height: "30px"
                },
                "&.Mui-expanded": {
                  minHeight: "0px"
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: 0
                }
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                marginTop={0.2}
              >
                📌 &nbsp; &nbsp;
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {pinnedMessages[0].messageId.userId.name} :{" "}
                </Typography>
                <Typography variant="body2" sx={{ marginLeft: "20px" }}>
                  {pinnedMessages[0].messageId.content}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "0 16px 10px 16px"
              }}
            >
              {pinnedMessages.slice(1).map(msg => (
                <div key={msg._id} className="pinned-message">
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{
                      height: "30px"
                    }}
                  >
                    📌 &nbsp; &nbsp;
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      {msg.messageId.userId.name} :{" "}
                    </Typography>
                    <Typography variant="body2" sx={{ marginLeft: "20px" }}>
                      {msg.messageId.content}
                    </Typography>
                  </Stack>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
        ) : (
          <div className="empty-pin-msg-text">
            <p> 📌 Pin today's plan here by selecting any message </p>
          </div>
        )}
      </div>
      {loading && messages.length === 0 ? (
        // true?
        <div className="loader">
          <Audio
            height="80"
            width="80"
            radius="9"
            color="black"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      ) : null}
      <div className="messages-container" ref={messagesEndRef}>
        {!loading && messages.length > 0 ? (
          <>
            {messages.map((e, index) => (
              <MessageComponent
                key={index}
                user={user}
                message={e}
                onReply={handleReply}
                pinMessage={pinThisMessage}
                unpinMessage={unpinThisMessage}
                setPinnedMessages={setPinnedMessages}
                isPinned={pinnedMessages.some(pm => pm._id === e._id)}
              />
            ))}
            {/* {console.log(hasMore)} */}
            {hasMore && (
              <Button onClick={handleLoadMore} style={{ alignSelf: "center" }}>
                Load More
              </Button>
            )}
          </>
        ) : (
          <Typography style={{ alignSelf: "center", marginTop: "20px" }}>
            {!loading && "No Messages Yet..."}
          </Typography>
        )}
      </div>
      {replyTo && (
        <div className="reply-container">
          <Typography variant="body2" color="textSecondary">
            Replying to {replyTo.userInfo.name}: {replyTo.message}
          </Typography>
          <button
            onClick={() => setReplyTo(null)}
            style={{ border: "none", background: "none", color: "#007bff" }}
          >
            Cancel
          </button>
        </div>
      )}
      <form
        style={{
          padding: "1rem",
          borderTop: "1px solid #ccc"
        }}
        onSubmit={submitHandler}
      >
        {/* {suggestions.map((suggestion, index) => (
        <Chip key={index} label={suggestion} onClick={() => handleSuggestionClick(suggestion)} style={{ margin: 2 }} />
      ))} */}
        <Stack
          direction="row"
          // height="100%"
          alignItems="center"
          justifyContent="space-between"
          position="relative"
          // border="2px solid red"
        >
          <TextField
            className="custom-textfield"
            style={{
              flex: 1,
              marginRight: "10px",
              backgroundColor: "#0000000D",
              border: "none",
              boxShadow: "none",
              border: "2px solid black",
              outline: "none"
              // overflow:"hidden",
              // borderRadius: "55px",
            }}
            placeholder={
              hasSentFirstMessage ? "Type a message" : "Introduce yourself"
            }
            value={message}
            onChange={handleMessage}
            multiline
            maxRows={4}
            variant="outlined"
            // variant="filled"
          />
          <IconButton
            sx={{
              backgroundColor: "black",
              color: "white",
              padding: "0.5rem",
              "&:hover": { bgcolor: "error.dark" }
            }}
            type="submit"
          >
            <Send />
          </IconButton>
        </Stack>
      </form>

      {!user.profileImage && (
        // <LockScreenOverlay onProfileImageUpload={handleProfileImageUpload} />
        <LockScreenOverlay
          groupId={groupId}
          onMessageSubmit={submitHandler}
          hasSentFirstMessage={hasSentFirstMessage}
        />
      )}

      {/* {(!user.profileImage?.url || !hasSentFirstMessage) && (
        // <LockScreenOverlay onProfileImageUpload={handleProfileImageUpload} />
        <LockScreenOverlay 
          groupId={groupId} 
          onMessageSubmit={submitHandler} 
          hasSentFirstMessage={hasSentFirstMessage}
          />
      )} */}
    </div>
  );
};

export default memo(ChatViewComponent);
